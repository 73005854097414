<template>
  <SkeletonForBoard v-if="loading" />
  <div v-else>
    <v-table class="table" fixed-header :height="maxmize ? '63vh' : '74vh'">
      <thead>
        <tr>
          <template v-for="stage in sortedStages" :key="stage">
            <th class="pb-0 tr tableheader" v-if="stage.no_of_issues > 0 || showZeroIssuesBoard"
              style="height: 42px; border-bottom: 1px solid #dbdbdb !important">
              <span class="TitleTxt pl-4 mt-2">{{ stage.name }}

                <v-chip label class="issues-total pa-2 mb-3 mr-3" density="compact">
                  <span v-if="assignedToMe">{{ getCount(stage.value) }}</span>
                  <span v-else>
                    {{ stage.no_of_issues }}
                  </span>
                </v-chip>
              </span>
            </th>
          </template>
        </tr>
      </thead>
      <tbody>

        <tr class="mt-2">
          <template v-for="stage in sortedStages" :key="stage">
            <td v-if="stage.no_of_issues > 0 || showZeroIssuesBoard" :draggable="true"
              @drop="onDrop($event, stage, group)" @dragenter.prevent @dragover.prevent style="
                  box-shadow: none !important;
                  padding: 0px 5px !important;
                  vertical-align: baseline;
                  background: #f1f1f1;
                  bottom: 24px;
                  top: 0px;
                  position: relative;
                  border-radius: 8px 8px 0px 0px;
                ">

              <v-card flat v-for="item in getIssue(stage.value, group)" :key="item" @click="atClick(item)"
                :draggable="true" @dragstart="dragStart($event, item, group)"
                class="pa-2 pt-3 pb-1 mb-2 cards onHover mt-2" elevation="-0.5">
                <v-card-subtitle class="pa-0 pl-0 issue-title">
                  <span>
                    <span v-if="item && item.account_name"
                      style="text-transform: uppercase;font-weight: bold;color: #000;">{{ item.account_code }} /</span>
                    <span>{{ item.reference_num }}</span>
                    <span v-if="item && item.multi_cycles > 1" style="color: red" class="ml-1"><v-icon>mdi
                        mdi-bug</v-icon></span></span>


                  <span class="pr-2" :style="moment().diff(
                    moment(item.end_date).format('YYYY-MM-DD'),
                    'days'
                  ) > 0
                    ? 'color:red'
                    : 'color:#636363'
                    ">
                    <v-icon size="12px" color="#636363" v-show="ifEndDate(item.end_date)">
                      mdi mdi-calendar-clock-outline
                    </v-icon>

                    {{
                      item && item.end_date && moment((item.end_date)).format(
                        "MM/DD/YYYY hh:mm A") != 'Invalid date'
                      ? moment((item.end_date)).format(
                        "MM/DD/YYYY"
                      )
                      : ""
                    }}

                  </span>
                </v-card-subtitle>
                <v-hover v-slot="{ isHovering, props }">
                  <v-card-text class="pa-1 ma-0" v-bind="props">
                    <v-row no-gutters dense>
                      <v-col cols="1" class="pa-0">
                        <CustomAvatar2 class="ma-0" :size="'coustom'" :fName="item.first_Name" :lName="item.last_name"
                          :imgUrl="item.n_image_url ? item.n_image_url : null" :theme="item.theme"></CustomAvatar2>
                      </v-col>
                      <v-col cols="9" class="pa-0 pl-3" style="display: flex;">

                        <!-- {{ item.name }}  -->
                        <span class="issueName">
                          {{
                            item.name.length < 35 ? item.name : item.name.substring(0, 35) + ".." }} </span>
                            <v-icon v-if="item && item.priority == 'critical'" class="pi pi-exclamation-triangle"
                              style="color:red;font-size: 10px;font-weight: 800; position: absolute;right: 28px;top: 35px;" />

                            <v-icon v-if="item.week_end_mails == 'yes'"
                              style="font-size: 12px;position: absolute;right: 42px;top: 35px;"
                              class="mdi mdi-circle-half-full" color="blue" />
                      </v-col>
                      <v-col cols="1" class="pr-4" style="margin: auto auto; display: block">

                      </v-col>
                      <v-col cols="1" offset="1" class="pa-0 pl-1 text-right" style="margin: auto auto; display: block">
                        <img v-if="isHovering && stage.value != 'closed' && showEdit(item)"
                          @click.stop="editDetails(item)" variant="text" class="editDetails btn-color"
                          src="https://new-synergy-snaps.s3.ap-south-1.amazonaws.com/public/icons/edit.svg"
                          style="width: 15px; height: 15px" />
                      </v-col>
                    </v-row>

                    <v-row dense class="no-gutters dense mt-0">
                      <!-- <v-col cols="4" class="pa-0 pl-1 pr-2" v-if="item.issue_type_name">
                          <v-chip class="onText" label v-on="on">
                            {{
                              item.issue_type_name < 11
                                ? item.issue_type_name
                                : item.issue_type_name.substring(0, 10) + "..."
                            }}
                          </v-chip>
                        </v-col> -->

                      <v-col cols="5"  v-if="item.sub_category_name">
                        <v-chip class="onText1" label v-on="on">

                          {{
                            item.sub_category_name
                            ? item.sub_category_name
                            : item.sub_category_name
                          }}
                        </v-chip></v-col>
                        <!-- <v-col cols="4" style="text-align:end"  v-if="item.task_name">
                         
                        </v-col> -->
                        
                      <v-col cols="7" style="text-align:end"  v-if="item.task_name">
                        <v-chip class="onText3 mr-1"  label v-on="on">
                              Estimate:{{ timeConvert(item.estimate)?timeConvert(item.estimate):0 }}h
                            </v-chip>
                          <v-chip class="onText3" label v-on="on">
                            Actual:{{ timeConvert(item.actual_hrs)?timeConvert(item.actual_hrs):0 }}h
                          </v-chip>
                        </v-col>
                    </v-row>
                  </v-card-text>
                </v-hover>
              </v-card>
            </td>
          </template>
        </tr>
      </tbody>
    </v-table>
    <v-dialog v-model="editDelDialog" :width="600" persistent>
      <EditDetails @closeDialog="close()" ref="EditDetails" :id="selectedId" @reload="getIssueType()" />
    </v-dialog>
  </div>
</template>
    
<script>
import SkeletonForBoard from "./SkeletonForBoard.vue"
import moment from "moment";
import EditDetails from "./views/EditDetails.vue";
//import IssueService from "@/api/service/Issue/IssueService";
import lodash from "lodash";
import WorkSpaceService from "@/api/service/WorkSpace/WorkSpaceService";
import CustomAvatar2 from '../../components/ui/CustomAvatar2.vue';
//import UserSelection from '@/menus/UserSelection.vue';
export default {
  props: [
    "issueData",
    "stages",
    "mainFilter",
    "filters",
    "showZeroIssuesBoard",
    "groups",
    "showGroup",
    "defaultGroup",
    "stageUpdated",
    "assignedToMe",
    "userId",
    "maxmize"
  ],
  components: {
    SkeletonForBoard,
    EditDetails,
    CustomAvatar2,
  },
  data() {
    return {
      loading: true,
      editDelDialog: false,
      issueType: [],
      selectedId: null,
      groupByData: [],
      stageFromws: [],
      sortedStages: [],
    };
  },
  watch: {
    mainFilter() {
      this.getIssueType(this.mainFilter);
    },
    assignedToMe() {
      console.log(this.userId, 'jjjjjjjjhh')
    },
    filters() {
      this.getIssueType(this.filters);
    },
    sortData() {
      this.sortIssueData(this.sortData);
    },
    stages(){
      this.getStageFromWs()
    }
  },
  created() {
    console.log(this.issueData, "pppppppppp")
    //this.getStageFromWs();
    // this.getIssueType();
  },

  mounted() {
    this.getStageFromWs();
    // this.getIssueType();
  },
  methods: {
    timeConvert(min) {
        let hours = (min) / 60;
        hours = hours.toFixed(2)
        return hours;
    },
    getCount(stage) {
      let user = this.userId;
      let newData = this.issueData.filter((el) => el.next_action === user && el.stage === stage);
      return newData.length;
    },
    ifEndDate(date) {
      if (moment((date)).format("MM/DD/YYYY") != 'Invalid date') {
        return true
      }
    },
    showEdit(item) {
      if (item.stage == "inprogress") {
        return false
      } else {
        return true
      }
    },
    async getStageFromWs() {
      let workSpaceId = localStorage.getItem("workSpaceId");
      let res = await WorkSpaceService.findOne(workSpaceId);
      let stages = (res && res[0] && Array.isArray(JSON.parse(res[0].stage))) ? JSON.parse(res[0].stage) : '';
      this.sortedStages = []
      for (let stage of stages) {
        let $stage = this.stages.filter((obj) => {
          return obj.value.toLowerCase() == stage
        })
        this.sortedStages = this.sortedStages.concat($stage)
      }
      // this.$emit("getStage");
      this.loading = false
      //alert(JSON.stringify(this.sortedStages))
      //this.sortedStages =  [...this.stages];
      /*console.log(this.sortedStages, "kas");
      this.sortedStages.sort((a, b) => {
        const aIndex = this.stageFromws.indexOf(a.value);
        console.log(this.aIndex, "kash");
        const bIndex = this.stageFromws.indexOf(b.value);
        console.log(this.aIndex, "kashi");
        if (aIndex === bIndex) {
          return 0;
        } else if (aIndex === -1) {
          return 1;
        } else if (bIndex === -1) {
          return -1;
        } else {
          return aIndex - bIndex;
        }
      });*/
      //this.stages
    },

    moment,

    dragStart(event, item, group) {
      console.log("xxxxxxxs", item);
      event.dataTransfer.dropEffect = "move";
      event.dataTransfer.effectAllowed = "move";
      event.dataTransfer.setData("parentIssueId", item.parent_issue_id);
      event.dataTransfer.setData("issueId", item.id);
      event.dataTransfer.setData("groupIssueId", group.id);
      event.dataTransfer.setData("oldGroup", group.id);
    },
    onDrop(event, stage, group) {
      console.log(event, "check2222");
      this.$emit("stageUpdated", {
        issueId: event.dataTransfer.getData("issueId"),
        parentIssueId: event.dataTransfer.getData("parentIssueId"),
        stage: stage.value,
        oldStage: event.dataTransfer.getData("oldStage"),
        groupValue: group.id,
        oldGroupValue: event.dataTransfer.getData("oldGroup"),
      });
    },

    hasCardsInColumn() {
      return this.getIssue().length > 0;
    },
    getIssue($stage, group) {
      console.log($stage, group, 'kyahhdbdjbv')
      if (this.showGroup) {
        return this.issueData.filter((issue) => {
          //console.log(issue.stage==$stage)
          return (
            (issue.stage ?? "").toLowerCase() ===
            ($stage ?? "").toLowerCase() &&
            (issue[this.defaultGroup.value] ?? "").toLowerCase() ===
            (group[this.defaultGroup.value] ?? "").toLowerCase()
          );
        });
      } else {
        return this.issueData.filter((issue) => {
          //console.log(issue.stage==$stage)
          return (
            (issue.stage ?? "").toLowerCase() === ($stage ?? "").toLowerCase()
          );
        });
      }
    },
    atClick(item) {
      let selectedId = item.id;
      // this.$router.push({ path: "/issueView2", query: { id: selectedId } });
      this.$store.dispatch('setIssueInView', selectedId)
    },
    sortIssueData(data) {
      console.log(data, "vrg4");
      this.issueType = lodash.orderBy(
        this.issueType,
        [`${data.value}`],
        ["desc"]
      );
    },
    async getSearchTheData(data) {
      if (data.length > 0) {
        let searchData = new RegExp(data.toLowerCase(), "gi");
        let $key = Object.keys(this.issueType);
        for (let i = 0; i < $key.length; i++) {
          let keyData = this.backupTheIssueData[$key[i]];
          let filterData = await keyData.filter((ele) => {
            console.log(ele.name.toLowerCase().search(searchData));
            return ele.name.toLowerCase().search(searchData) > -1;
          });
          this.issueType[$key[i]] = filterData;
          this.issueType[$key[i]] = [...this.issueType[$key[i]]];
        }
      } else {
        this.issueType = this.backupTheIssueData;
        this.issueType = { ...this.issueType };
      }
    },
    /*async getIssueType(cond) {
      var res = await IssueService.findIs(cond);
      this.issueType = res;
      this.backupTheIssueData = JSON.parse(JSON.stringify(this.issueType));
      this.$emit("getStage");
      this.loading = false
    },*/

    editDetails(item) {
      this.selectedId = item.id;
      this.editDelDialog = true;
    },

    close() {
      this.editDelDialog = false;
    },
  },
};
</script>
    
<style  scoped>
v-table {
  border-spacing: 0 0.5rem;
}

>>>.v-chip.v-chip--size-default {
  --v-chip-size: 0.875rem;
  --v-chip-height: 14px;
  /* font-size: 4px; */
  padding: 0 3px;
}

>>>.v-chip--variant-tonal .v-chip__underlay {
  background-color: inherit;
  color: #272727 !important;
}

>>>.v-chip__content {
  color: #272727 !important;
  font-size: 9px;
  text-overflow: clip;
}

>>>.title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #e8f3f9;
  height: 60px;
  width: 280px;
}

.TitleTxt {
  font-size: 13px;
  font-weight: 600;
  color: #636363;
  display: block;
  margin: auto auto;
  /* text-align: start */
}

.issue-title {
  font-size: 11px;
  font-weight: 400;
  color: #636363;
  display: flex;
  justify-content: space-between;
}

.cards {
  width: 280px;
  padding-top: 5px;
}

>>>.onText {
  font-size: 12px;
  color: #636363;
  font-weight: 500;
  text-align: start;
  background: #f8d6e6;
  opacity: 1 !important;
}

.ontext2 {
  font-size: 12px;
  font-weight: 550;
}

.onText1 {
  font-size: 10px;
  font-weight: 500;
  text-align: start;
  background: #d6f8f6;
}

.onText3 {
  font-size: 12px;
  font-weight: 500;
  text-align: start;
  background: #d6f8f6;
}

.table {
  background: inherit;
  max-width: 93vw;
  margin-right: 40px !important;
}

>>>.tableheader {
  box-shadow: none !important;
  background: #fafafa !important;
  padding: 0px 5px !important;
  min-width: 280px;
  border-radius: 8px 8px 0px 0px;
}

.avatar {
  background: #e6def9;
}

.onHover:hover {
  background-color: #f9f6ff;
}

.issueName {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 1;
  line-clamp: 1 !important;
  height: 15px;
  color: #272727;
  font-size: 12px;
  font-weight: 400;
  -webkit-box-orient: vertical;
}



>>>.v-table>.v-table__wrapper>table {
  width: auto;
  border-spacing: 10px 1px !important;
}




.TitleTxt {
  font-size: 14px;
  font-weight: 600;
  color: #636363;
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.issues-total {
  background-color: #c7f0d0;
  color: #ffffff;
}

>>>.v-table .v-table__wrapper>table>tbody>tr:not(:last-child)>td,
.v-table .v-table__wrapper>table>tbody>tr:not(:last-child)>th {
  border-bottom: none;
}

.trGroup:hover {
  background: transparent;
  cursor: pointer;
}

/* .groupRow:hover{
  width: 100%;
  border-radius:4px ;
  border: 1px solid rgb(18, 88, 171);
} */
>>>::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

>>>::-webkit-scrollbar-track {
  background: #e8f3f9;
}

>>>::-webkit-scrollbar-thumb {
  background: rgb(0, 0, 0, 0.12);
  border-radius: 8px;
}


/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #e8f3f9;
}
</style>