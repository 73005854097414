<template>
	<v-card flat width="100%" style="border-radius: 12px; overflow: auto" class="pl-5">
		<v-form @submit.prevent="save" ref="form">
			<v-card-item>
        <v-card-title v-if="from && from=='form'">
          <b>Change Option</b>
          <v-btn @click="$emit('closeCard')" elevation="0" density="comfortable" icon="mdi-close" class="x-closeIcon"></v-btn>
        </v-card-title>
      </v-card-item>

			<v-row class="pl-4">
				<v-col cols="10" class="pa-0 pt-2 pl-2">
					<v-label class="pb-1" style="font-size:14px;color:#272727;font-weight:600">Column Name</v-label>
					<TextFieldComponent placeholder="Column Name" v-model="formData.column_name"
						:disabled="showReadOnly" />
				</v-col>
				<v-col cols="10" class="pa-0 pt-1 pl-2">
					<v-label class="pb-1" style="font-size:14px;color:#272727;font-weight:600">Label</v-label>
					<TextFieldComponent placeholder="Label" v-model="formData.label" :disabled="showReadOnly" />
				</v-col>
				<v-col cols="10" class="pa-0 pt-1 pl-2">
					<v-label class="pb-1" style="font-size:14px;color:#272727;font-weight:600">Data Type</v-label>
					<EnumAutoCompleteVue density="compact" v-model="formData.data_type" :selected="formData.data_type"
						:showReadOnly="viewType == 'add' || this.viewType == 'view'" :domain="'DATA-TYPE'"
						placeholder="Datatype" @changeEnumData="changeDatatype($event)" :rules="dataTypeRule" />
				</v-col>
				<v-col cols="10" class="pa-0 pt-1 pl-2">
					<v-label class="pb-1" style="font-size:14px;color:#272727;font-weight:600">Field Type</v-label>
					<EnumAutoCompleteVue density="compact" v-model="formData.field_type" :selected="formData.field_type"
						:showReadOnly="formData.data_type === 'date' || formData.data_type === 'datetime' || this.viewType == 'view'"
						:domain="'FIELD-TYPE'" placeholder="FieldType" @changeEnumData="changeEnumData($event)"
						:rules="fieldTypeRule" />
				</v-col>
				<v-col cols="10" class="pa-0 pt-1 pl-2" v-if="showSource">
					<v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Source
						Type
						<span v-if="!showReadOnly"
							style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
					<EnumAutoCompleteVue density="compact" v-model="formData.source_type"
						:selected="formData.source_type" :showReadOnly="viewType == 'add' || this.viewType == 'view'"
						:domain="'SOURCE-TYPE'" placeholder="Source" @changeEnumData="changeSource($event)"
						:rules="sourceRule" />
				</v-col>
				<v-col cols="10" class="pa-0 pt-1 pl-2" v-if="showModel">
					<v-label class="pb-1" style="font-size:14px;color:#272727;font-weight:600">Source</v-label>
					<AutoComplete placeholder="Table" :item-title="'value'" :disabled="showReadOnly"
						:item-value="'value'" v-model="formData.source" :items="tableData" />
				</v-col>
				<v-col cols="10" class="pa-0 pt-1 pl-2" v-if="showModel">
					<v-label class="pb-1" style="font-size:14px;color:#272727;font-weight:600">Value</v-label>
					<TextFieldComponent placeholder="Value" v-model="formData.value" :disabled="showReadOnly" />
				</v-col>
				<v-col cols="10" class="pa-0 pt-1 pl-2" v-if="showModel">
					<v-label class="pb-1" style="font-size:14px;color:#272727;font-weight:600">Text</v-label>
					<TextFieldComponent placeholder="Text" v-model="formData.text" :disabled="showReadOnly" />
				</v-col>
				<v-col cols="10" class="pa-0 pt-1 pl-2" v-if="showEnum">
					<v-label class="pb-1" style="font-size:14px;color:#272727;font-weight:600">Domain</v-label>
					<AutoComplete placeholder="Domain" :item-title="'domain'" :disabled="showReadOnly"
						:item-value="'domain'" v-model="formData.enum_domain" :items="domainData" />
				</v-col>
				<v-col cols="2" class="pt-9" v-if="showEnum">
					<v-btn class="ml-2 add-button" width="22px" height="22px" density="compact" icon="mdi-plus"
						@click="showEnumDialog"></v-btn>
				</v-col>
				<v-col cols="10" class="pa-0 pt-1 pl-2" v-if="showModel">
					<v-checkbox label="Multi Select" color="primary" v-model="formData.multi_select"></v-checkbox>
				</v-col>
				<v-col cols="4">
					<v-switch label="Filtered Required" color="primary" v-model="formData.filter_required"></v-switch>
				</v-col>
				<v-col cols="4">
					<v-switch label="Form Required" color="primary" v-model="formData.form_required"></v-switch>
				</v-col>
				<v-col cols="4">
                    <v-switch label="Column Required" color="primary" v-model="formData.column_required"></v-switch>
				</v-col>
				<v-col cols="10" class="pa-0 pt-1 pl-2" v-if="formData.form_required == 1">
					<v-label class="pb-1" style="font-size:14px;color:#272727;font-weight:600">Score</v-label>
					<TextFieldComponent placeholder="Score" v-model="formData.score" :disabled="showReadOnly" />
				</v-col>
				<v-col cols="10" class="pa-0 pt-1 pl-2" v-if="formData.filter_required == 1">
					<v-label class="pb-1" style="font-size:14px;color:#272727;font-weight:600">Filter Sequence</v-label>
					<TextFieldComponent placeholder="Filter Sequence" v-model="formData.sequence"
						:disabled="showReadOnly" />
				</v-col>
				<v-col cols="10" class="pa-0 pt-1 pl-2" v-if="formData.form_required == 1">
					<v-label class="pb-1" style="font-size:14px;color:#272727;font-weight:600">Field width</v-label>
					<TextFieldComponent placeholder="Field width" v-model="formData.width" :disabled="showReadOnly" />
				</v-col>
				<v-col cols="10" class="pa-0 pt-1 pl-2" v-if="formData.column_required == 1">
					<v-label class="pb-1" style="font-size:14px;color:#272727;font-weight:600">Table Sequence</v-label>
					<TextFieldComponent placeholder="Table Sequence" v-model="formData.table_sequence"
						:disabled="showReadOnly" />
				</v-col>
			</v-row>

			<v-card-action class="mr-3">
				<v-row justify="end" class="dialogBottom" v-if="!showReadOnly">
					<v-btn class="mr-2 cancel" @click="cancel">Cancel</v-btn>
					<v-btn class="save" :loading="saveClicked" type="submit">Save</v-btn>
				</v-row>
			</v-card-action>
		</v-form>
	</v-card>

	<v-dialog persistent v-model="enumDialog" width="500px">
		<AddEnum
			ref="AddEnum"
			@reload="getEnumDomain()"
			@closeDialog="setFalse()"
			:viewType="false"
		/> </v-dialog>
</template>
<script>

import AutoComplete from "@/components/ui/AutoComplete.vue";
import EnumAutoCompleteVue from '@/components/others/EnumAutoComplete.vue';
import TextFieldComponent from "@/components/TextFieldComponent.vue";
import ListModelDetailService from "@/api/service/ListModelDetail/ListModelDetailService";
import commonService from '@/common.service';
import EnumService from "@/api/service/Enum/EnumService";
import AddEnum from "../../Others/Enum/AddEnum.vue";

export default {
	props:["editData","from"],
	components: {
		AutoComplete,
		TextFieldComponent,
		EnumAutoCompleteVue,
		AddEnum
	},
    watch: {
        editData(){
			if(this.editData){
				this.formData = this.editData 
				this.changeDatatype(this.formData.data_type)
				this.changeEnumData(this.formData.field_type)
				this.changeSource(this.formData.source_type) 
            }
		}
    },
	data() {
		return {
			enumDialog:false,
			showReadOnly: false,
			tableData: [],
			showSource: false,
			showModel: false,
			showEnum: false,
			showOption: false,
			formData: {},
			domainData: []
		};
	},
	async created() {
		this.getSourceData()
		this.getEnumDomain() 
		if(this.editData){
				this.formData = this.editData 
				this.changeDatatype(this.formData.data_type)
				this.changeEnumData(this.formData.field_type)
				this.changeSource(this.formData.source_type)
		}
	},
	methods: {
		showEnumDialog() {
			this.enumDialog = true
		},
        cancel(){      
            this.formData = {}      
			this.showSource = false
			this.showModel = false
			this.showEnum = false
			this.showOption = false
			this.$emit('closeCard')
        },
		async getSourceData() {
			let res = await commonService.getTables()
			this.tableData = res.length > 0 ? res : []
			// console.log(this.tableData, "List model data")
		},
		async getEnumDomain() {
			try {
				let res = await EnumService.getDomain()
				this.domainData = res.length > 0 ? res : []
			}
			catch (e) {
				console.log(e)
			}
		},
		changeEnumData($event) {
			this.showSource = $event == 'autocomplete' || $event == 'select' ? true : false
			this.formData.field_type = $event
		},
		changeSource($event) {
			if ($event == 'model') {
				this.showModel = true
				this.showEnum = false
				this.showOption = false
				this.formData.options = null
				this.formData.enum_domain = null
			}
			else if ($event == 'enum') {
				this.showModel = false
				this.showEnum = true
				this.showOption = false
				this.formData.options = null
				this.formData.source = null
				this.formData.text = null
				this.formData.value = null
			}
			else {
				this.showModel = false
				this.showEnum = false
				this.showoptions = true
				this.formData.enum_domain = null
				this.formData.source = null
				this.formData.value = null
				this.formData.text = null
			}
			this.formData.source_type = $event;
		},
		setFalse() {
			this.enumDialog = false
		},
		changeDatatype($event) {
			if ($event == 'date' || $event == 'datetime' || $event == 'time') {
				this.formData.field_type = 'datepicker';
			}
			this.formData.data_type = $event
		},
		async save() {

			if (this.formData && this.formData.source_type && (this.formData.source_type == 'enums' || this.formData.source_type == 'enum')) {
				this.formData.text = 'display_value'
				this.formData.value = 'data_value'
			}
			if (this.formData.id) {
				await ListModelDetailService.update(this.formData);
				this.formData = {}
				this.$emit('reset-field')

			} else {
				let output = await ListModelDetailService.update(this.formData);
				console.log(output);
				this.$emit('reset-field')
				this.$router.push('/setting/technical/model')
				this.$emit("reload", true);
			}
		}
	},
};
</script>

