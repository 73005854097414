<template>
	<SkeletonReport v-if="loading" />
	<div v-else-if="show && !loading">
		<div class="centered-container" v-if="Nodata">
			<strong> No data found for the selected filter</strong>
		</div>
		<div class="overlay" v-if="loadingData"></div>
		<div class="centered-container" v-if="loadingData">
			<v-row justify="center" align="center">
				<v-col cols="12">
					<strong>Report is Loading, Please wait....</strong>
				</v-col>
				<v-col cols="12" align="center">
					<v-progress-circular indeterminate color="primary"></v-progress-circular>
				</v-col>
			</v-row>
		</div>
		<div class="centered-container" v-if="showDownloadMsg">
			<v-row justify="center">
				<v-col cols="12" align="center">
					<strong
						>Downloading the report as Excel file <br> since the result too huge to
						preview</strong
					>
				</v-col>
			</v-row>
		</div>

		<ReportPreview
			:data="srcData"
			@columnEditor="columnEditor"
			:config="headerData"
			:filterData="filterData"
			:backBtn="backBtn"
			:savedFilters="savedFilters"
			:selectedFilter="selectFilter"
			@filter-id="selectedFilterId"
			@deleteItem="deleteFilterDetails"
			@goBack="onGoBack"
			@formChanged="formChanged"
			@subscribe="subscribe"
		/>

		<EditColumns
			ref="EditColumns"
			:data="columnData"
			@editColumns="sendColumns"
			@allSelected="allSelected"
			@refresh-header ="getModelData('editColumn')"
		/>
	</div>

	<v-dialog v-model="recurringDialog" width="850px" persistent>
		<CreateJob
			style="overflow: auto"
			ref="createjob"
			:filterData="savedFilters"
			:jobId="null"
			@reload="hasRecurringTask"
			endPoint="reports"
			:params="params"
			:reportData="modelData"
			@closeDialog="cancel()"
		></CreateJob>
	</v-dialog>
</template>
<script>
import EditColumns from "./EditColumns.vue";
import SkeletonReport from "./SkeletonReport.vue";
import { ReportPreview } from "@tiburadev/vue-comp";
// import ListModelService from "@/api/service/ListModel/ListModelService";
import ListModelDetailsService from "@/api/service/ListModelDetail/ListModelDetailService";
import commonService from "@/common.service";
import ListModelFilterService from "@/api/service/ListModelFilter/ListModelFilterService";
import CreateJob from "@/modules/Jobs/Create.vue";
import JobsService from "@/api/service/Jobs/JobsService.js";

export default {
	components: {
		ReportPreview,
		SkeletonReport,
		EditColumns,
		CreateJob,
	},
	data() {
		return {
			backBtn: {
				label: "Back",
				show: true,
			},
			loadingData: false,
			columnDataCopy:null,
			userData: null,
			recurringTasks: null,
			loading: true,
			filterData: null,
			headerData: [],
			selectFilter: null,
			show: false,
			menu: false,
			showDownloadMsg: false,
			srcData: [],
			modelData: {},
			columnData: [],
			recurringDialog: false,
			Nodata: false,
			showColumn: this.columnData,
			finalColumn: this.columnData,
			modelDetailsData: [],
			savedFilters: null,
			titleStyle: {
				bold: 600,
				underline: true,
				font: "verdana",
				fontSize: "24px",
				color: "0000",
				align: "left",
				background: "",
				format: "",
				case: "upper",
				border: {
					left: true,
					bottom: true,
					right: true,
					top: true,
				},
			},
			subTitleStyle: {
				bold: "600",
				underline: true,
				font: "verdana",
				fontSize: "18px",
				color: "#272727",
				align: "left",
				background: "",
				format: "",
				case: "upper",
				border: {
					left: true,
					bottom: true,
					right: true,
					top: true,
				},
			},
			headerStyle: {
				bold: true,
				underline: true,
				font: "verdana",
				fontSize: "14px",
				color: "",
				align: "left",
				background: "",
				format: "",
				case: "upper",
				border: {
					left: true,
					bottom: true,
					right: true,
					top: true,
				},
			},
			detailsStyle: {
				rowHeight: 101,
				bold: true,
				underline: true,
				font: "verdana",
				fontSize: "14px",
				color: "",
				format: "",
				case: "upper",
				align: "left",
				background: "",
				params: { report_id: parseInt(this.$route.query.model_id) },
				border: {
					left: true,
					bottom: true,
					right: true,
					top: true,
				},
			},
		};
	},
	mounted() {
		this.finalColumn = this.columnData;
	},

	async created() {
		let user = localStorage.getItem("user_details");
		this.userData = user ? JSON.parse(user) : {};
		await this.getSavedFilters();
		await this.getModelData(null);
		this.hasRecurringTask();
	},

	computed: {
		getJobId() {
			if (this.recurringTasks && this.recurringTasks.length > 0) {
				return this.recurringTasks[0].id;
			}
			return null;
		},
	},
	methods: {
		cancel() {
			this.recurringDialog = false;
		},
		async deleteFilterDetails($event) {
			let data = {
				id: $event.id,
				active: 0,
				deleted: 1,
			};
			await ListModelFilterService.update(data);
			console.log(data, "data");
		},
		async selectedFilterId($event) {
			if ($event) {
				try {
					let opt = await commonService.getSelectedFilter($event);
					this.backupselectFilter = JSON.parse(JSON.stringify(opt));
					let filterData = {};
					if (opt && opt.length) {
						for (let i = 0; i < opt.length; i++) {
							filterData[opt[i].column_name] =
								(opt[i].defaults.includes("[") && opt[i].defaults.includes("]")) ||
								(opt[i].defaults.includes("{") && opt[i].defaults.includes("}"))
									? JSON.parse(opt[i].defaults)
									: opt[i].defaults;
						}
					}
					this.selectFilter = filterData;
					console.log(this.selectFilter, "uuuiiiee");
				} catch (e) {
					console.log(e);
					this.selectFilter = null;
				}
			}
		},
		async subscribe() {
			this.params = { report_id: parseInt(this.$route.query.model_id) };
			this.recurringDialog = true;
		},
		async getSavedFilters() {
			try {
				let res = await ListModelFilterService.findAll({
					model_id: this.$route.query.model_id,
					created_by: this.userData.id,
				});
				this.savedFilters = res && res.length ? res : [];
			} catch (e) {
				this.savedFilters = [];
				console.log(e);
			}
		},

		async hasRecurringTask() {
			let $recurring = await JobsService.hasRecurringTask({
				reportId: this.$route.query.model_id,
				type: "report",
				end_point: "reports",
			});
			this.recurringTasks = $recurring;
		},
		async sendColumns(data) {
			const indexToRemove = this.showColumn.findIndex(
				(item) => item.value === data.value
			);
			if (indexToRemove > -1) {
				this.showColumn.splice(indexToRemove, 1);
			} else {
				this.showColumn.push(data);
			}

			console.log(this.showColumn, "kkkaaaayyy");
			this.finalColumn = this.showColumn;
			console.log(this.finalColumn, "kkkkkkkkkkkkkkkkkkkkkkkkh");

			await this.getModelData(null);
		},

		async allSelected(data) {
			if (this.finalColumn != data) {
				this.finalColumn = data;
			} else {
				this.finalColumn = [];
			}
			await this.getModelData(null);
		},

		columnEditor() {
			this.$refs.EditColumns.drawer = !this.$refs.EditColumns.drawer;
		},
		async getFilterData() {
			try {
				let res = await ListModelDetailsService.findAll({
					model_id: this.$route.query.model_id,
					filter_required: 1,
				});
				let columnData = await commonService.setFilterData(res);
				console.log(columnData, "columndata");
				// columnData.forEach((item)=>{
				// 	if(item.options && item.options.length>0 && item.label!="Candidate")
				// 	item.options.unshift({id:0,name:"Select All",code:"Select All"})
				// })
				this.columnDataCopy=columnData;
				this.filterData = {
					title: "Report Form",
					width: "700",
					closeIcon: false,
					height2: "600",
					withContainer: false,
					layout: "fixed",
					columns: 12,
					sections: [
						{
							columns: columnData,
						},
					],
				};
				this.filterData = { ...this.filterData };
				this.show = true;
				this.loading = false;
				// this.filterData = res && res.length ? res :[]
			} catch (e) {
				this.filterData = {};
				console.log(e);
			}
		},
		async getModelData(flag) {

			try {
				let res = await ListModelDetailsService.getReportHeader({model_id:this.$route.query.model_id})
					console.log(res,"response")
				// let res = await ListModelService.findAll({
				// id: this.$route.query.model_id,
				// });
				this.modelData = res && res.length ? res[0] : {};
				if (
					this.modelData &&
					this.modelData.model_type &&
					this.modelData.model_type == "query"
				) {
					// alert('common service 1')
					let data = await commonService.getReport({
						modelId: this.modelData.id,
					});
					// alert('common service 2')
					this.srcData = data && data.length ? data : [];
				}
			} catch (e) {
				this.modelData = [];
				console.log(e);
			}
			this.commonData = await this.getHeaderData(this.modelData);
			this.columnData =this.modelData && this.modelData.detail_data?JSON.parse(this.modelData.detail_data):[]

			this.columnData = await commonService.getSort(this.columnData, "table_sequence", "asc");
			let $headerData = {
				detail: {
					style: this.detailsStyle,
					columns: this.columnData,
				},
				header: this.commonData,
			};
			this.headerData = $headerData;
			console.log(this.headerData, "headerData");
			this.loading =false
			this.show = true;
			if(!flag){
			this.getFilterData();
			}
		},
		async formChanged($event) {
			console.log($event,"filterarray");
			for (let key in $event) {
                if ( $event[key].length === 0){
                   delete $event[key]; 
                   }
            }
			
			let FilterArray=JSON.parse(JSON.stringify($event));
		
			for (let key in FilterArray) {
		if(FilterArray[key] && FilterArray[key].length>0 && FilterArray[key].includes("Select All")){
				FilterArray[key]=[];
				let index=this.columnDataCopy.findIndex((item)=>{
					return item.column==key;
				})
			this.columnDataCopy[index].options.forEach((item) => {
                if (item.code !== "Select All") {
                  FilterArray[key].push(item.code?item.code:item.name);
                }
            });
            }  
		if(FilterArray[key] && FilterArray[key].length>0 && FilterArray[key].includes(0)){
				FilterArray[key]=[];
				let index=this.columnDataCopy.findIndex((item)=>{
					return item.column==key;
				})
			this.columnDataCopy[index].options.forEach((item) => {
                if (item.id != 0) {
                  FilterArray[key].push(item.id);
                }
            });
            }  
		}
		
			console.log(FilterArray,"filterarray123");
			this.showDownloadMsg = false;
			try {
				if (FilterArray && FilterArray.saveFilter && FilterArray.saveFilter.id) {
					ListModelFilterService.update(FilterArray.saveFilter);
					for (let i = 0; i < this.backupselectFilter.length; i++) {
						if (FilterArray[this.backupselectFilter[i]["column_name"]]) {
							this.backupselectFilter[i].defaults = FilterArray[
								this.backupselectFilter[i]["column_name"]
							]
								? FilterArray[this.backupselectFilter[i]["column_name"]]
								: "";
						}
					}
					await commonService.updateFilter({ data: this.backupselectFilter });
					delete FilterArray.saveFilter;
				}
				let obj = {
					tableName: this.modelData.model,
					modelId: this.$route.query.model_id,
					filter: FilterArray,
				};
				this.loadingData = true;
				let data = await commonService.getReport(obj);
				console.log(data, "kishoredata");
				if (data.length == 0) {
					this.Nodata = true;
				} else {
					this.Nodata = false;
				}
				if (data && data.url) {
					window.open(data.url, "_blank");
					this.srcData = [];
					this.showDownloadMsg = true;
					this.loadingData = false;
				} else {
					this.showDownloadMsg = false;
					this.srcData = data && data.length ? data : [];
					console.log(this.srcData, "srcDatasrcData");
					console.log(this.srcData, "src Dataaa");
				}
				if (this.srcData != null) {
					this.loadingData = false;
				} else {
					this.loadingData = true;
				}

				this.getSavedFilters();
			} catch (e) {
				this.srcData = [];
				console.log(e);
			}
		},
		async getColumnStyleData(value) {
			let $data = [];
			value = await commonService.getSort(value, "table_sequence", "asc");
			for (let $i = 0; $i < value.length; $i++) {
				let $obj = {
					label: value[$i].label,
					value: value[$i].column_name,
					fixed: $i == 0 ? true : false,
					width: "75px",
					sequence: "",
					visible: true,
					expression: "",
					link: "",
					dataType: value[$i].data_type,
				};
				$data.push($obj);
			}
			return $data;
		},
		onGoBack() {
			this.$router.push("/reportList");
		},
		getHeaderData(value) {
			let header = {
				title: [
					{
						text: value.name,
						size: "100%",
						style: this.titleStyle,
					},
				],
				subTitle: [
					{
						text: "",
						size: "30",
						style: this.subTitleStyle,
					},
				],
				filters: [
					{
						column: "accountCode",
						values: ["BNO", "TRF", "TER"],
					},
				],
				downloadable: true,
				sortable: true,
				style: this.headerStyle,
			};
			return header;
		},
	},
};
</script>
<style scoped>
.overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.2); /* semi-transparent black background */
	z-index: 999; /* place the overlay above other content */
}

.centered-container {
	position: fixed;
	display: flex;
	align-items: center;
	justify-content: center;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 1; /* place the loader above the overlay */
}
.centered-container1 {
	position: fixed;
	display: flex;
	align-items: center;
	justify-content: center;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.p-menu .p-menuitem-link {
	cursor: pointer;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	text-decoration: none;
	overflow: hidden;
	position: relative;
	overflow: inherit;
	color: #495057;
	padding: 0.75rem 1.25rem;
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
}

.p-menu .p-menuitem-link:hover {
	background-color: #dee2e6;
}

>>> .v-card--flat {
	box-shadow: none;
	padding-bottom: 65px;
}
</style>
