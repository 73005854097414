<template>
	<v-card flat height="100vh">
		<v-row class="mt-1 ml-2">
			<v-col cols="12">
				<span style="font-weight: 500;
    font-size: 20px;">Request Board</span> 
			</v-col>
		</v-row> 
                <v-row class="mb-4 ml-2">
                    <v-col cols="3" v-for="item of data" :key="item">
                        <v-card
                        class="mx-auto hover-card"
                        max-width="400"
                        >
                        <div class="circle">
                            <img class="img-cls" :src="item.imageUrl" />
                        </div>
                        <v-divider class="mt-15 mb-10" ></v-divider>
                                <v-card-title>{{ item.name || '' }}</v-card-title>
                            <v-card-text>
                                <div>{{item.summary }}</div>
                            </v-card-text>
                            <v-card-actions>
                                <v-btn color="primary" text="Open" @click="selectItem(item)"></v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-row>
		</v-card>
</template>
<script>

export default {
	data(){
		return {
			selectedOption:null,    
            data :[
                {
                    code: "VACATIONREQUEST",
                    name:"Vacation Request",
                    summary:'',
                    imageUrl:
                    "https://new-synergy-snaps.s3.ap-south-1.amazonaws.com/public/icons/live.png",
                    route:"/vacationRequest"
                },]
		}
	},
	created(){
       
        
	},
	methods:{
		selectItem(item){
			this.selectedOption = item ?item:{}
			this.$router.push(item.route) 
		}
	}

}
</script>
<style scoped>
.img-cls{
    height: 25px; 
    position: absolute;
    bottom: 84px;
    top: 8px;
    left: 13px;   
}
.circle {
    width: 50px;
    height: 50px;
    background-color: #eaedef;
    border-radius: 50%;
    position: absolute;
    bottom: 84px;
    top: 35px;
    left: 45px;
    z-index:1
}
.hover-card {
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.hover-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}
</style>
