<template>
  <v-card width="100%" style="border-radius: 12px; overflow: hidden">
    <v-form  ref="form">
      <v-card-item>
        <v-card-title>          
          Deletion request
          <v-btn
            @click="closeDialog"
            elevation="0"
            density="comfortable"
            icon="mdi-close"
            class="x-closeIcon"
            style="font-size: 20px;"
          ></v-btn>
        </v-card-title>
      </v-card-item>

      <v-card-text>
        <v-row no-gutters class="mt-3 pa-2">
          <v-col>
            confirm! Do you need need to delete the form module?
           </v-col> 
        </v-row>
      </v-card-text>

      <v-card-action class="mr-3">
        <v-row justify="end" class="dialogBottom">
          <v-btn class="mr-2 cancel"  @click="closeDialog">No</v-btn>
          <v-btn class="save" @click="$emit('confirm-to-approval', data)">Yes</v-btn>
        </v-row>
      </v-card-action>
    </v-form>
  </v-card>
</template>

<script>
export default {
  props: {
  },
  methods: {
    closeDialog() {
      this.$emit('close');
    },
  },
};
</script>
