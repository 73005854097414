<template>
  <div class="card">
    <v-card-title class="toggle-button title" @click="toggleCollapse">Time
      <span>
        <v-icon>{{ isCollapsed ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon></span>
    </v-card-title>

    <div class="mt-2" v-show="!isCollapsed">
      <v-expansion-panels class="card1" variant="accordion" v-for="(item, index) in formData" :key="item">
        <v-expansion-panel elevation="0">
          <v-expansion-panel-title class="name">
            <v-row>
              <v-col cols="6">{{ index }}</v-col>
              <v-col class="hour1">{{ calculateTotalHours(item) }} Minutes</v-col>
            </v-row>
          </v-expansion-panel-title>
          <v-list style="margin-top: -4%;">
            <v-expansion-panel-text style="margin-top: -10px;">
              <v-row no-gutters="" v-for="value in item" :key="value">
                <v-col cols="8" class="pt-3" style="display: flex;">
                  <span>
                    <CustomAvatar2 class=" mt-1 ml-2" :fName="value.firstName" :lName="value.lastName" :theme="value.theme"  :size="'medium'" />
                  </span>
                  <span class="pl-4 pt-2  name">{{ value.userName }}</span>
                </v-col>

                <v-col offset="" class="mt-5 pl-3" cols="3">
                  <span align="end " class="hour">{{ value.hours }} Minutes</span>
                </v-col>
                <v-col class="text-right chippp mt-4 " cols="1">
          <v-btn @click="viewTime(value)" variant="text" class="  btn-color">
                <img src="https://new-synergy-snaps.s3.ap-south-1.amazonaws.com/public/icons/Eye.svg"
                  style="width:16px;height:20px">
              </v-btn>
        </v-col>
                <v-divider class="mt-2 divider"></v-divider>
              </v-row>
            </v-expansion-panel-text>
          </v-list>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>

  </div>
  <v-dialog v-model="showTimeDialog" :width="700" persistent>
    <TimeSheets ref="viewTime" @closeDialog="closeViewTime()" :timeSheetDefaults="timeData" :viewType="viewType"></TimeSheets>
  </v-dialog>
</template>

<script>
import CustomAvatar2 from '@/components/ui/CustomAvatar2.vue';
import TimeSheetService from '@/api/service/TimeSheet/TimeSheetService';
import TimeSheets from '@/modules/issues/views/TimeSheets.vue'
// import commonService from '@/common.service';
// import lodash from "lodash";
export default {
  props: ['id'],
  components: {
    CustomAvatar2,
    TimeSheets
  },
  watch: {
    id() {
      if (this.id) {
        this.getById(this.id);
      }
    },
    getRefresh() {
      if (this.getRefresh) {
        this.getById(this.$route.query.id);
      }
      else {
        this.getById(this.$route.query.id);
      }
    }
  },
  computed: {
    getRefresh() {
      return this.$store.getters.getRefreshTimeSheet;
    }
  },
  data() {
    return {
      showTimeDialog:false,
      isCollapsed: false,
      dialog: false,
      viewType:false,
      totalHours: 0,
      timeData:{},
      formData: {},
    }
  },
  created() {
    if(this.id){
      this.getById(this.id);

    }
    else if(this.$route.query.id){
    this.getById(this.$route.query.id);
    }

  },

  methods: {

    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed;
    },

    async viewTime($value){
      this.showTimeDialog=true
      this.viewType=true
      console.log($value,'check789')
      this.timeData=$value

    },
    // async getTimeSheetDefaults(){
    //          this.formData
    // },
    async closeViewTime(){
      this.showTimeDialog=false
    },
    // getTimeSheetDefaults() {
    //     return {
    //       user_id: this.formData.userId,
    //       id:this.formData.id,
    //       projectName: this.formData.projectName,
    //       accountName: this.formData.accountName,
    //       taskName: this.formData.taskName,
    //       issueName: this.formData.issueName,
    //       startedAt: this.formData.startedAt,
    //       endedAt: this.formData.endedAt,
    //       description: this.formData.description,
    //       actualHours:this.formData.hours          
    //     };
      
    // },





    async getById(id) {
      if(id){
  let res = await TimeSheetService.getTimeForIssue({ "issue_id": id });
  this.formData = res;
      }
  
},
    getAvatarName(value) {
      let fName =
        value && value.firstName
          ? value.firstName.charAt(0) : ""
      let lName = value && value.lastName
        ? value.lastName.charAt(0)
        : "";
      let name = fName + lName
      return name;
    },


    calculateTotalHours(item) {
      let totalHours = 0;
      for (const value of item) {
        totalHours += value.hours;
      }
      return (Math.round(totalHours * 100) / 100).toFixed(2);
    },
  }

}
</script>


<style scoped>
.divider {
  border-top: 1px dotted #000000;
  opacity: .5;
}

.toggle-button {
  cursor: pointer;
}

.toggle-button span {
  margin-right: 8px;
}

.toggle-button v-icon {
  transition: transform 0.3s;
}

.toggle-button:hover v-icon {
  transform: rotate(180deg);
}

>>>.v-expansion-panels--variant-accordion .v-expansion-panel-title__overlay {
  transition: 0.3s border-radius cubic-bezier(0.4, 0, 0.2, 1);
  background-color: white;
}

>>>.v-list {
  overflow: auto;
  padding: 0px 0;
  position: relative;
  border-color: rgba(var(--v-border-color), var(--v-border-opacity));
  border-style: solid;
  border-width: 0;
  box-shadow: 0px 0px 0px 0px var(--v-shadow-key-umbra-opacity, rgba(0, 0, 0, 0.2)), 0px 0px 0px 0px var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.14)), 0px 0px 0px 0px var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.12));
  border-radius: 0;
  background: rgba(var(--v-theme-surface));
  color: rgba(var(--v-theme-on-surface), var(--v-high-emphasis-opacity));
}

>>>.v-expansion-panels {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style-type: none;
  padding: 0;
  width: 100%;
  position: relative;
  z-index: 0;
  padding-bottom: unset;
}

>>>.v-expansion-panel-text__wrapper {
  padding: 8px 24px 16px;
  flex: 1 1 auto;
  max-width: 100%;
  padding-bottom: unset;
}

.card {
  border: 1px solid #dfdfdf;
  border-radius: 4px !important;
  padding-bottom: 0 !important;
  /* border-radius: 2px; */
}

.title {
  border-bottom: 1px solid #dfdfdf;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  margin: 0px;
}

.name {
  font-size: 14px;
  font-weight: 600;
  color: #636363;
}

.hour {
  white-space: nowrap;
  font-size: 10px;
  font-weight: 400;
  padding-top: 20px;
}

.hour1 {
  font-size: 12px;
  font-weight: 600;
  text-align: end;
  /* padding-top: 20px; */
}
</style>
