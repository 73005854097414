<template>
  <v-card flat dense class="pa-0 ma-0 rounded" :elevation="0">
    <v-card-title class="d-flex align-center pl-0 pa-0 ma-0" dense>
  <div class="d-flex align-center">
    <span style="font-size: 16px; font-weight: 600; color: #272727">Description</span>
    <v-btn
      v-if="showEdit && exceptStage && !editButton"
      icon
      density="compact"
      @click="openDialog('editDescription')"
      variant="text"
      class="btn-color"
    >
      <img
        style="width: 16px; height: 16px"
        src="https://new-synergy-snaps.s3.ap-south-1.amazonaws.com/public/icons/edit.svg"
      />
    </v-btn>
    <v-dialog v-model="dDialog" :width="600">
      <EditDescription
        @closeDialog="close()"
        ref="EditDescription"
        :id="issueData.id"
        @reload="getIssueData()"
      />
    </v-dialog>
    <v-btn
      icon
      density="compact"
      @click="openDialog('expandDescription')"
      variant="text"
      class="btn-color"
      v-if="issueData && issueData.description"
    >
      <img
        style="width: 20px; height: 18px"
        src="https://new-synergy-snaps.s3.ap-south-1.amazonaws.com/public/icons/expand.png"/>
    </v-btn>
  </div>
  <span class="fix-description ml-auto" style="font-size: 14px; color: #272727; font-weight: 500" @click="updateDescription()">
    Fix the description
  </span>
</v-card-title>

    <v-card-text
      class="description"
      v-if="issueData && issueData.description"
      style="
        background-color: #e0f3fd53;
        min-height: 250px;
        max-height: 300px;
        overflow-y: auto;
        font-size: 12px;
        font-weight: 400;
        padding-top: 1%;
      "
      v-html="issueData && issueData.description ? this.description : ''"
    />
    <v-card-text
      v-else
      class="description"
      style="
        background-color: #e0f3fd53;
        min-height: 250px;
        max-height: 300px;
        overflow-y: auto;
        font-size: 14px;
        font-weight: 600;
        padding-top: 1%;
        display: grid;
        place-items: center;
      "
    >
      <p>Please add some description it helps to know about your task</p>
    </v-card-text>
  </v-card>
  <v-dialog v-model="dialogOpen" class="dialogOpen" width="55%" max-width="55%">
    <EditDescription
      v-if="options.editDescription"
      @closeDialog="closeDialog()"
      ref="EditDescription"
      :id="issueData.id"
      @reload="
        (desc) => {
          $emit('descriptionChanged', desc);
        }
      "
    />
    <v-card class="descriptionCard" v-if="options.expandDescription">
      <v-row>
        <v-col cols="4">
          <v-card-title>View Description</v-card-title>
        </v-col>
        <v-col class="descBtn">
          <!-- <v-btn icon density="compact" @click="editDesc()" variant="text" class="ml-7 editBtn btn-color" v-if="issueData.stage != 'closed' && showEdit && !issueData.mail_box_id
                      ">
                      <img src="https://new-synergy-snaps.s3.ap-south-1.amazonaws.com/public/icons/edit.svg"
                        style="width: 16px; height: 20px" />
                    </v-btn> -->
          <v-btn
            @click="closeDialog()"
            elevation="0"
            density="comfortable"
            icon="mdi-close"
            class="x-closeIcon"
          ></v-btn>
        </v-col>
      </v-row>
      <v-card-text
        style="background-color: rgba(224, 243, 253, 0.325)"
        class="description"
        v-html="description"
      />
    </v-card>
  </v-dialog>
</template>
<script>
import EditDescription from "./EditDescription.vue";
import IssueService from "@/api/service/Issue/IssueService";
export default {
  props: ["issueData", "showEdit", "exceptStage"],
  components: {
    EditDescription,
  },
  data() {
    return {
      dialogOpen: false,
      options: {
        editDescription: false,
        expandDescription: false,
      },
      description: "",
      editButton: false,
    };
  },
  created() {
    this.description = this.issueData.description;
    console.log(this.issueData, "issue");
    if (this.issueData.mail_details) {
      this.editButton = true;
    } else {
      this.editButton = false;
    }
  },
  watch: {
    issueData: {
      deep: true,
      handler() {
        this.description = this.issueData.description;
        if (this.issueData.mail_details) {
          this.editButton = true;
        } else {
          this.editButton = false;
        }
      },
    },
  },
  methods: {
    resetOptions() {
      this.options.editDescription = false;
      this.options.expandDescription = false;
    },
    async updateDescription(){
      try{
        let res = await IssueService.updateDescription({id:this.issueData.id})
        if(res && res.data && res.data.data ){
          this.description = res.data.data[0]['0']['issues']['description']
        }    
      }catch(e){
        console.log(e)
      }
    },
    openDialog($type) {
      this.dialogOpen = true;
      this.resetOptions();
      this.options[$type] = true;
    },
    closeDialog() {
      this.dialogOpen = false;
      this.resetOptions();
    },
  },
};
</script>
<style scoped>
.description {
  font-size: 14px;
  font-weight: 400;
  color: #272727;
}
.fix-description{
  text-decoration: underline;
  cursor: pointer;
  font-size: 14px;
  color: #272727; 
  font-weight: 500
}

>>> p {
  font-size: 14px !important;
  font-weight: 400 !important;
  color: #272727 !important;
}
</style>
