<template>

    <TimerCount v-if="nextAction == this.userData.id && issueData.stage!='closed'  && issueData.stage !='qapending' && showTimer" :showHomeCard="false" taskType='issue' :keyId="issueId" :showPlay="true" :hideCard="false"
        :startStopWatch="startTimer" :stopStopWatch="stopTimer" :color="'rgb(18, 88, 171)'" />

    <v-btn @click="timeClicked" variant="text" class="pa-2 btn-color" v-if="issueData && issueData.stage!='closed'">
        <img src="https://new-synergy-snaps.s3.ap-south-1.amazonaws.com/public/icons/Tumer_fill_gray.svg"
            style="width:16px;height:16px">
        &nbsp; Time
    </v-btn>
    <!-- <v-btn @click="qaClicked" variant="text" class="pa-2 btn-color">
        <img src="https://new-synergy-snaps.s3.ap-south-1.amazonaws.com/public/icons/qa.svg" style="width:16px;height:16px">
        &nbsp; QA
    </v-btn> -->
    <v-btn variant="text" id="view-menu-activators" class="pa-2 btn-color">
        <img src="https://new-synergy-snaps.s3.ap-south-1.amazonaws.com/public/icons/Eye.svg" style="width:16px;height:16px">
        &nbsp; {{ originalData && originalData.watchers ? originalData.watchers.length : 0 }} Views
    </v-btn>
    <v-btn variant="text" id="follow-menu-activators" class="pa-2 btn-color" v-if="originalData.created_by == userData.id">
        <img src="https://new-synergy-snaps.s3.ap-south-1.amazonaws.com/public/icons/Pin.svg" style="width:16px;height:16px">
        &nbsp;
        {{ originalData && originalData.followers ? originalData.followers.length : 0 }} Followers
    </v-btn>
    <v-btn variant="text" @click="updateFollow()" class="pa-2" v-if="originalData.followers.includes(userData.id) && originalData.created_by != userData.id">
        <v-img src="https://new-synergy-snaps.s3.ap-south-1.amazonaws.com/public/icons/user-unfollow-line.svg"
            style="width:15px;" /> &nbsp; Unfollow
    </v-btn>
    <v-btn variant="text" @click="updateFollow()" class="pa-2" v-if="!originalData.followers.includes(userData.id) && originalData.created_by != userData.id">

        <v-img src="https://new-synergy-snaps.s3.ap-south-1.amazonaws.com/public/icons/user-voice-svgrepo-com.svg"
            style="width:20px;" /> &nbsp; Follow
    </v-btn>
    <v-menu  location="start" class="menuu">
        <template v-slot:activator="{ props }">
            <v-btn variant="text" id="share-menu-activators" v-bind="props" class="pa-2 btn-color">
                <img src="https://new-synergy-snaps.s3.ap-south-1.amazonaws.com/public/icons/share_outline.svg"
                    style="width:16px;height:16px">
                    <v-tooltip activator="parent" location="bottom">Share</v-tooltip>
                <!-- &nbsp; {{ views && views.length >= 1 ? views.length : 1 }} -->
            </v-btn>
        </template>
        <v-card min-width="170" height="210px">
            <v-list dense>
                <div dense class="px-2 py-0">
                    <TextFieldComponent dense @input="getSearch" @click.stop :placeholder="search" v-model="search"
                        append-inner-icon="mdi-magnify" />
                </div>

                <v-list-item dense class="pt-0 mt-0" @click="$emit('userSelected', item)" v-for="(item, index) in shareUser"
                    :key="index">
                    <div class="reportedBy"> 
                        <CustomAvatar2 class=" ml-1 mt-0 pl-0" :fName="item.first_name" :lName="item.last_name" :theme="item.theme" :imgUrl="item.image_url"  :size="'small'" />
                    <span class="pl-4 text-caption">{{ item.name }}</span>
                </div>
                </v-list-item>
            </v-list>
        </v-card>
    </v-menu>
    <v-btn @click="addIssue()" variant="text"  class="pa-2 btn-color">
        <v-icon style="height: 18px; width: 18px;">mdi-plus</v-icon>
        <v-tooltip activator="parent" location="bottom">Add Task</v-tooltip>
    </v-btn>

    <MenuOptions activator="#view-menu-activators" :id="issueId" :options="totalViews" :showAvatar="true" />
    <MenuOptions activator="#follow-menu-activators" :options="totalFollowers" :showAvatar="true" />
</template>
<script>
import CustomAvatar2 from '@/components/ui/CustomAvatar2.vue';
import MenuOptions from '../../../menus/MenuOptions'
import IssueService from "@/api/service/Issue/IssueService";
import UserService from '@/api/service/User/UserService';
import TimerCount from '@/menus/Timer.vue'
// import Avatar from 'primevue/avatar';
import TimeSheetService from '@/api/service/TimeSheet/TimeSheetService';
import TextFieldComponent from '@/components/TextFieldComponent.vue';

export default {
    props: ["views", "watchers", 'username', 'issueId', "issueData" , "nextAction"],
    components: {
        MenuOptions,
        TimerCount,
        CustomAvatar2,
        TextFieldComponent

    },
    computed: {
        getAssigneeDetails() {
      return this.$store.getters.getAssignee;
    },
        getTimerInProgress() {
            return this.$store.getters.getInProgress
        },
        getTimerHide() {
            return this.$store.getters.getHideTimer
        },
    },
    watch: {
        getAssigneeDetails() {
      if (this.getAssigneeDetails) {
          this.getUser()
      } else {
        this.getUser()
      }},
        getTimerInProgress() {
            if (!this.getTimerInProgress) {
                this.startTimer = false;
                this.stopTimer = true;
            }
            else {
                this.startTimer = true;
                this.stopTimer = false;
            }
            this.$emit("checkStage" ,this.stopTimer)
        },
        getTimerHide() {
            if (this.getTimerHide) {
                this.showTimer = false
            }
            else {
                this.checkTimer()
            }
        },
        issueData() {
            this.originalData = JSON.parse(JSON.stringify(this.issueData))
        }
    },
    data() {
        return {
            startTimer: false,
            stopTimer: false,
            showTimer: true,
            startIssueTimer: true,
            userData: {},
            shareUser:[],
            backupShareUser:[],
            originalData: {},
            users: [],
            search:null,
            totalViews: [],
            totalFollowers: []
        }
    },
    async created() {
        this.getUser()
        this.originalData = this.issueData
        this.getWatchedUsers();
        this.getFollowedUsers();
        // console.log()
        this.userData = JSON.parse(localStorage.getItem("user_details"))
        // let timesheet = []
        this.getShareUserData()

    },
    methods: {
        getShareUserData(){
           UserService.findAll({active:1}).then((res)=>{
            this.shareUser=res
            this.backupShareUser =[...res]
           }).catch(e=>{
            this.shareUser=[]
            this.shareUser =[]
            console.log(e)
           })
        },
        async getIssueTimerShow(){
            let timesheet = []
        try {
            timesheet = await TimeSheetService.findAll({ issue_id: this.$route.query.id, stage: 'pending' });
        }
        catch {
            timesheet = []
        }
        let issue = []
        try {
            issue = await IssueService.findAllIssues({ id: this.$route.query.id });
        }
        catch {
            issue = []
        }
        let $timer = localStorage.getItem('timer')
        if ($timer) {
            $timer = JSON.parse($timer)
            this.showTimer = $timer.type == 'issue' && $timer.issueId == this.issueId ? true : false
            if (this.showTimer) {
                if (timesheet.length > 0) {
                    if (timesheet[0].resource_id == this.userData.id) {
                        this.showTimer = true
                    }
                    else {
                        this.showTimer = false
                    }
                }
                else {
                    this.showTimer = true
                }
            }
        }
        else {
            if (issue.length > 0 && issue[0].next_action == this.userData.id) {
                if (timesheet.length > 0) {
                    if (timesheet[0].resource_id == this.userData.id) {
                        this.showTimer = true
                    }
                    else {
                        this.showTimer = false
                    }
                }
                else {
                    this.showTimer = true
                }
            }
            else {
                this.showTimer = false
            }
        }
    },
        async getUserIdFromLS() {
      let res =await IssueService.findOne(this.issueData.id)
      this.UserID = res.length>0?res[0]:{}
      this.userLS = JSON.parse(localStorage.getItem("user_details"));
      // this.showEdit = this.userLS.id==this.UserID.next_action?true:false
    },
    
    getSearch() {
            if (this.search.length > 0) {
                // alert(this.backupShareUser.length)
                let searchStr = new RegExp(this.search, 'gi');
                this.shareUser = this.backupShareUser.filter(ele => {
                    return ele.name.search(searchStr) > -1

                })
            }
            else {

                this.shareUser = [...this.backupShareUser]
            }


        },
        async checkTimer() {
            let $timer = localStorage.getItem('timer')
            if ($timer) {
                $timer = JSON.parse($timer)
                this.showTimer = $timer.type == 'issue' && $timer.issueId == this.issueId ? true : false

            }
            else{
                console.log(this.issueData,"888")
                if(this.issueData.stage !='closed' && this.issueData.stage !='qapending'  ){
                    this.showTimer =true
                }
            }
        },
        async getFollow() {
            console.log(this.originalData.followers.includes(this.userData.id),
             'this.originalData.followers.includes(this.userData.id)')
            return !this.originalData.followers.includes(this.userData.id)
        },
        async getUnFollow() {
            console.log(this.originalData.followers.includes(this.userData.id), 'this.originalData.followers.includes(this.userData.id)')
            return await this.originalData.followers.includes(this.userData.id)
        },
        async getWatchedUsers() {
            if (this.originalData.watchers.length > 0) {
                this.totalViews = await UserService.findAll({ id: this.originalData.watchers });
                console.log(this.totalViews,'totallllll')
            } else {
                this.totalViews = []
            }
            console.log(this.totalViews, 'after the service call')
        },
        async getFollowedUsers() {
            if (this.originalData.followers.length > 0) {
                this.totalFollowers = await UserService.findAll({ id: this.originalData.followers });
                this.$emit('refreshData')
            } else {
                this.$emit('refreshData')
                this.totalFollowers = []
            }
        },
        updateFollow() {
            let opt =this.originalData.followers.includes(this.userData.id)
            if(opt){
              let index =this.originalData.followers.findIndex(k=>k==this.userData.id)
              this.originalData.followers.splice(index,1)
            }
            else{
                this.originalData.followers.push(this.userData.id)
            }
            let $followers = JSON.parse(JSON.stringify(this.originalData.followers))
            let $obj = {};
            $obj.id = this.originalData.id;
            $obj.followers = JSON.stringify($followers)
            $obj.type = 'follow'
            IssueService.updateFollowers($obj)
            this.getFollowedUsers()
        },
        addIssue() {
            this.$router.push({
                path: "/addQuickIssue",
                query: { "from": "topMenu" }
            });
        },

        Searching() {
      console.log('Searchhhhhhhhhh', this.search);
    },

        timeClicked() {
            this.$emit("timeClicked")
        },

        async getUser() {
            let res = await UserService.findAll()
            this.users = res;
            console.log(this.users, "userss")
        },
        getAvatarName(value) {
            let avatarName =
                value && value.first_name
                    ? value.first_name.charAt(0)
                    : "" + value && value.last_name
                        ? value.last_name.charAt(0)
                        : "";
            return avatarName;
        },

    }
}
</script>
<style scoped>
.btn-color {
    color: #333333;
    font-size: 10px;
}

.stopwatch-color {
    height: 30px;
    color: white;
    margin-left: 15px;
}

.textUser{
    margin-left: 55px;
    margin-bottom: 10px;
}
>>>.reportedBy{
    display: inline-flex;
    margin-top: -20px;
}

</style>