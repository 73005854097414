<template>
  <v-card width="100%" style="border-radius: 12px; overflow: auto">
    <v-form @submit.prevent="save" ref="form">
      <v-card-item>
        <v-card-title
          >{{
            viewType == "edit" ? "Edit" : viewType == "view" ? "View" : "Add"
          }}
          <b>User</b>
          <v-btn
            @click="close()"
            elevation="0"
            density="comfortable"
            icon="mdi-close"
            class="x-closeIcon"
          ></v-btn>
        </v-card-title>
      </v-card-item>

      <v-card-text>
        <v-row no-gutters class="mt-3 pa-2">
          <v-col cols="12" class="py-0">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Email
              <span
                v-if="!showReadOnly"
                style="color: #cf0707; padding-left: 2px; font-weight: 700"
                >*</span
              ></v-label
            >
            <TextFieldComponent
              density="compact"
              placeholder="Email"
              v-model="formData.email"
              :disabled="showUserDetails"
              :rules="emailRules"
              @input="anychange()"
            />
            <v-error v-if="existsMessage">Email already exists</v-error>
          </v-col>

          <v-col cols="6" class="dropdown" v-if="showUserDetails">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Date Of Joining
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span>
            </v-label>
            <DatePicker :showReadOnly="showReadOnly"  :data="formData.date_of_join" :rules="dateRule" :placeholder="'Date'" @selectDate="selectDate($event)" :disabled="showReadOnly" />
            <v-error v-if="dateError">Date Is required</v-error>
          </v-col>
          <v-col cols="6" class="py-0 pl-3" v-if="showUserDetails">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Mobile
              <span
                v-if="!showReadOnly"
                style="color: #cf0707; padding-left: 2px; font-weight: 700"
              ></span
            ></v-label>
            <TextFieldComponent
              density="compact"
              placeholder="Mobile"
              @keypress="allowNumbers($event)"
              v-model="formData.mobile"
              :disabled="showReadOnly"
              :rules="mobileRules"
            />
            <v-error v-if="mobileError" class="mobileError"
              >Must 10 Numbers Only</v-error
            >
          </v-col>
          <v-row v-if="showUserDetails">
            <!-- <v-col cols="6" v-if="showPassword">
                <v-label
                  class="pb-1"
                  style="font-size: 14px; color: #272727; font-weight: 600"
                  >Password
                  <span
                    v-if="!showReadOnly"
                    style="color: #cf0707; padding-left: 2px; font-weight: 700"
                    >*</span
                  ></v-label
                >
                <TextFieldComponent
                  density="compact"
                  placeholder="Password"
                  v-model="formData.password"
                  :disabled="showReadOnly"
                  :rules="passwordRules"
                 
                />
              </v-col> -->
            <!-- <v-col cols="6" v-if="showPassword">
                <v-label
                  class="pb-1"
                  style="font-size: 14px; color: #272727; font-weight: 600"
                  >Confirm Password
                  <span
                    v-if="!showReadOnly"
                    style="color: #cf0707; padding-left: 2px; font-weight: 700"
                    >*</span
                  ></v-label
                >
                <TextFieldComponent
                  density="compact"
                  placeholder="Confirm Password"
                  v-model="formData.confirmPassword"
                  :disabled="showReadOnly"
                  :rules="confirmPassRules"
                 
                />
              </v-col> -->

            <v-col cols="6" class="pb-0">
              <v-label
                class="pb-1"
                style="font-size: 14px; color: #272727; font-weight: 600"
                >First Name
                <span
                  v-if="!showReadOnly"
                  style="color: #cf0707; padding-left: 2px; font-weight: 700"
                  >*</span
                ></v-label
              >
              <TextFieldComponent
                density="compact"
                placeholder="First Name"
                v-model="formData.first_name"
                :disabled="showReadOnly"
                :rules="firNameRules"
              />
            </v-col>
            <v-col cols="6" class="pb-0">
              <v-label
                class="pb-1"
                style="font-size: 14px; color: #272727; font-weight: 600"
                >Last Name
                <span
                  v-if="!showReadOnly"
                  style="color: #cf0707; padding-left: 2px; font-weight: 700"
                ></span
              ></v-label>
              <TextFieldComponent
                density="compact"
                placeholder="Last Name"
                v-model="formData.last_name"
                :disabled="showReadOnly"
              />
            </v-col>

            <v-col cols="6" class="py-0">
              <v-label
                class="pb-1"
                style="font-size: 14px; color: #272727; font-weight: 600"
                >Location
                <span
                  v-if="!showReadOnly"
                  style="color: #cf0707; padding-left: 2px; font-weight: 700"
                  >*</span
                ></v-label
              >
              <AutoComplete
                :items="locationData"
                placeholder="Locations"
                item-title="name"
                item-value="id"
                :disabled="showReadOnly"
                v-model="formData.location_id"
                :rules="locationRule"
              />
            </v-col>

            <v-col cols="6" class="py-0">
              <v-label
                class="pb-1"
                style="font-size: 14px; color: #272727; font-weight: 600"
                >Role
                <span
                  v-if="!showReadOnly"
                  style="color: #cf0707; padding-left: 2px; font-weight: 700"
                  >*</span
                ></v-label
              >

              <AutoComplete
                :items="roleData"
                placeholder="Role"
                item-title="name"
                @update:modelValue="roleChanged"
                item-value="id"
                :disabled="showReadOnly"
                v-model="formData.role_id"
                :rules="roleRule"
              />
            </v-col>
            
          <v-col cols="12" class="dropdown">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Department [E]
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
            <!-- <EnumAutoComplete density="compact" :showReadOnly="showReadOnly"  placeholder="Department" :selected="formData.department" :domain="'DEPARTMENT'"
              @changeEnumData="changeDepartment($event)" :rules="departmentRule"/> -->
              <AutoComplete
                    :items="departmentData"
                    :multiple="true"
                    item-value="data_value"
                    item-title="display_value"
                    density="compact"
                    placeholder="Department"
                    v-model="formData.department"
                    :rules="departmentRule"
                    :disabled="showReadOnly"
                  />
          </v-col>

            <v-col cols="6" class="py-0" v-if="reportingManagerData.length > 0">
              <v-label
                class="pb-1"
                style="font-size: 14px; color: #272727; font-weight: 600"
                >Reporting Manager
                <!-- <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span> -->
              </v-label>

              <AutoComplete
                :items="reportingManagerData"
                placeholder="Reporting Manager"
                item-title="name"
                item-value="id"
                :disabled="showReadOnly"
                v-model="formData.reporting_mgr_id"
              />
            </v-col>

            <v-col cols="6" class="py-0">
              <v-label
                class="pb-1"
                style="font-size: 14px; color: #272727; font-weight: 600"
                >Work Timing
                <span
                  v-if="!showReadOnly"
                  style="color: #cf0707; padding-left: 2px; font-weight: 700"
                  >*</span
                ></v-label
              >

              <AutoComplete
                :items="workTimingData"
                item-value="id"
                item-title="name"
                density="compact"
                placeholder="Work Timings"
                v-model="formData.work_timings"
                @update:modelValue="workTimingChanged"
                :disabled="showReadOnly"
              />
              <v-error v-if="wtError">Work Timing is Required.</v-error>
              <v-chip
                closable
                class="selectedChip"
                label
                :disabled="showReadOnly"
                v-for="item in selectedWorkTimings"
                :key="item"
                @click:close="removeChips(item)"
              >
                {{ item.name }}
              </v-chip>
            </v-col>

            <v-col cols="6" class="py-0">
              <v-label
                class="pb-1"
                style="font-size: 14px; color: #272727; font-weight: 600"
                >Time Zone
                <span
                  v-if="!showReadOnly"
                  style="color: #cf0707; padding-left: 2px; font-weight: 700"
                  >*</span
                ></v-label
              >

              <TimeZoneList
                :updateData="formData.time_zone"
                @timeZoneChange="timeZoneChange"
                :rules="timeZoneRule"
              />
              <!-- <v-error v-if="timeZoneError">Email already exists</v-error> -->
            </v-col>

            <v-col cols="4" class="py-0">
              {{ color }}
              <v-label
                class="pb-1"
                style="font-size: 14px; color: #272727; font-weight: 600"
                >Color
                <span
                  v-if="!showReadOnly"
                  style="color: #cf0707; padding-left: 2px; font-weight: 700"
                ></span
              ></v-label>
              <br />
              <input class="colorPick" type="color" v-model="formData.color" />
            </v-col>
          </v-row>
        </v-row>
      </v-card-text>
      <v-card-actions class="mr-3">
        <v-row justify="end" class="dialogBottom" v-if="!showReadOnly">
          <v-btn class="mr-2 cancel" @click="dialog = close()">Cancel</v-btn>
          <v-btn class="save" type="submit" :loading="loading">{{
            !showUserDetails ? "Check User" : "Add User"
          }}</v-btn>
        </v-row>
      </v-card-actions>
    </v-form>
  </v-card>
</template>
<script>
import randomColor from "randomcolor";
import TextFieldComponent from "@/components/TextFieldComponent.vue";
import RoleService from "@/api/service/Role/RoleService";
import LocationService from "@/api/service/Location/LocationService";
import WorkTimingService from "@/api/service/WorkTiming/WorkTimingService";
import AutoComplete from "@/components/ui/AutoComplete.vue";
import TimeZoneList from "@/components/others/TimeZoneList.vue";
import UserService from "@/api/service/User/UserService";
import commonService from "@/common.service";
import DatePicker from "@/components/ui/DatePicker.vue";
// import EnumAutoComplete from "@/components/others/EnumAutoComplete.vue";
import EnumService from "@/api/service/Enum/EnumService";

export default {
  props: ["id", "viewType"],
  components: {
    TextFieldComponent,
    AutoComplete,
    TimeZoneList,
    DatePicker,
    // EnumAutoComplete
  },

  data() {
    return {
      emailRules: [
        (value) => !!value || "E-mail is required.",
        (value) => {
          if (/.+@.+\..+/.test(value)) {
            this.isValidEmail = true;
            return true;
          }
          this.isValidEmail = false;
          return "E-mail must be valid.";
        },
      ],
      // emailRules: [value => {
      //   if (value) return true

      //   return 'E-mail is requred.'
      // },
      // value => {
      //   if (/.+@.+\..+/.test(value)) return true

      //   return 'E-mail must be valid.'
      // }],
      passwordRules: [(value) => !!value || "Password is Required."],
      confirmPassRules: [(value) => !!value || "Confirm Password is Required."],
      firNameRules: [(value) => !!value || "First Name is Required."],
      locationRule: [(value) => !!value || "Location is Required."],
      roleRule: [(value) => !!value || "Role is Required."],
      timeZoneRule: [(value) => !!value || "Timezone is Required."],
      dateRuleRule: [(value) => !!value || "Date Of Joining is Required."],
      departmentRule:[(value) => !!value || "Department is Required."],

      // T: [(value) => !!value || "Code is Required."],
      // lastNameRules:[(value) => !!value || "Last Name is Required."],
      //mobileRules:[(value) => !!value || "Mobile is Required."],
      showReadOnly: false,
      isValidEmail: false,
      loading: false,
      dialog: false,
      type: null,
      showUserDetails: false,
      existsMessage: false,
      showPassword: false,
      emailValidation: "",
      departmentData:[],
      reportingManagerData: [],
      backupWorkTimingData: [],
      selectedWorkTimingId: [],
      selectedWorkTimings: [],
      workTimingData: [],
      wtError: false,
      timeZoneError: false,
      formData: {
        id: null,
        name: null,
        username: null,
        email: null,
        password: null,
        company_id: null,
        default_site_id: null,
        first_name: null,
        last_name: "",
        token: null,
        token_expires: null,
        api_token: null,
        activation_date: null,
        secret: null,
        secret_verified: null,
        tos_date: null,
        is_superuser: null,
        role: null,
        preferences: null,
        lock_user: null,
        team: null,
        mobile: null,
        auth_type: null,
        auth_via: null,
        auth_service_id: null,
        activation_key: null,
        ldap_string: null,
        default_company_id: null,
        super_user: null,
        user_type: null,
        invalid_attempts: null,
        user_security: null,
        resource_id: null,
        refresh_token: null,
        role_id: null,
        image_profile_id: null,
        company_list: null,
        color_code: null,
        customer_id: null,
        provider_type: null,
        date_format: null,
        date_of_join: null,
      },
      dateError: false,
    };
  },

  watch: {
    id() {
      if (this.id) {
        this.formData = {};
        this.getById(this.id);
      }
    },
  },
  created() {
    this.getDepartmentData()
    this.getLocationData();
    this.getRoleData();
    this.getWorkTimingsData();
    this.formData.theme = randomColor({
      seed: Math.floor(256 * Math.random()),
      luminosity: "dark",
      format: "hex", // e.g. 'rgba(9, 1, 107, 0.6482447960879654)'
    });
    if (this.id) {
      this.getById(this.id);
    }
    if (this.viewType) {
      this.showReadOnly = this.viewType == "edit" ? false : true;
    }
  },
  methods: {
    randomColor,
    async getDepartmentData(){
      try{
        let res = await EnumService.findAll({domain:'DEPARTMENT'});
        this.departmentData = res.length > 0 ? await commonService.getSort(res, "display_value", "asc") : [];
        // this.backupDeptData = JSON.parse(JSON.stringify(this.departmentData))
      }
      catch(e){
        console.log(e);
      }
    },
    anychange() {
      this.existsMessage = false;
    },
    
    changeDepartment($value) {
      this.formData.department = $value
    },
    async roleChanged($event) {
      this.selectedRoleId = $event;
      this.getReportingManager(this.selectedRoleId);
    },

    workTimingChanged($value) {
      this.wtError = false;
      this.formData.work_timings = null;
      this.selectedWorkTimingId.push($value);
      this.showChips();
    },
    timeZoneChange($event) {
      this.formData.time_zone = $event;
    },

    showChips() {
      let chipRes = commonService.getMultipleChip(
        this.backupWorkTimingData,
        this.selectedWorkTimingId,
        "id"
      );
      this.workTimingData = commonService.getObjectSliceMethod(
        JSON.stringify(this.backupWorkTimingData),
        this.selectedWorkTimingId,
        "id"
      );
      this.selectedWorkTimings = chipRes;
      this.workTimingData = [...this.workTimingData];
    },
    removeChips(data) {
      let index = this.selectedWorkTimingId.findIndex((k) => k == data.id);
      if (index > -1) {
        this.selectedWorkTimingId.splice(index, 1);
      }
      this.showChips();
      this.wtError = this.selectedWorkTimingId.length == 0;
    },

    allowNumbers(evt) {
      evt = evt ? evt : window.event;
      let expect = evt.target.value.toString() + evt.key.toString();
      if (!/^[+]?[0-9]*\.?[0-9]*$/.test(expect)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    async getWorkTimingsData() {
      let res = await WorkTimingService.findAll();
      this.workTimingData =
        res.length > 0 ? await commonService.getSort(res, "name", "asc") : [];
      this.backupWorkTimingData = JSON.parse(
        JSON.stringify(this.workTimingData)
      );
      console.log(this.backupWorkTimingData, "gooooddd");
    },
    async getLocationData() {
      let res = await LocationService.findAll();
      this.locationData =
        res.length > 0 ? await commonService.getSort(res, "name", "asc") : [];
    },
    async getRoleData() {
      let res = await RoleService.findAll();
      this.roleData =
        res.length > 0 ? await commonService.getSort(res, "name", "asc") : [];
    },

    async getReportingManager(id) {
      let res = await UserService.getUserByRoleType({ role_id: id });
      this.reportingManagerData = res.filter((user) => user.active === 1);
      this.reportingManagerData = this.reportingManagerData.length > 0 ? 
      await commonService.getSort(this.reportingManagerData, "name", "asc") : [];
    },
    selectDate($event){
      this.dateError = false
      this.formData.date_of_join =$event
    },
    close() {
      this.$emit("closeDialog");
    },
    async getById(id) {
      let res = await UserService.findOne(id);
      this.formData = res[0];
      this.formData.department = this.formData.department && this.formData.department.length>0? JSON.parse(this.formData.department):null;

    },

    async save() {
      let validation = await this.$refs.form.validate();
      this.loading = this.dateError ? false:true;

      // console.log(this.showPassword , this.formData.password ,this.formData.confirmPassword,"0000000")
      // alert(this.showPassword && this.formData.password !=this.formData.confirmPassword)
      if (!this.isValidEmail) {
        this.loading = false
        return;
      }
      console.log(this.formData);
      if (this.formData.email && this.formData.email) {
        // }
       
        if (!this.showUserDetails) {
          UserService.checkUser({ email: this.formData.email }).then((res) => {
            if (res.message == "Email already exists") {
              this.loading = false
              this.existsMessage = true;
            } else {
              this.loading = false
              this.showUserDetails = true;
              console.log(this.showUserDetails);
              if (res.password) {
                this.showPassword = true;
              } else {
                this.showPassword = false;
              }
            }
          });
        } 


        else if (!validation.valid || this.selectedWorkTimingId.length == 0) {
          console.log("Not valid");
          this.loading = false
          this.dateError = this.formData.date_of_join ? false : true;
          if (this.selectedWorkTimingId.length == 0) {
            this.wtError = true;
          }
        } else if (this.formData.id) {
          this.dateError = this.formData.date_of_join ? false : true;
           if(!this.dateError){
            this.loading = true
            this.formData.department = JSON.stringify(this.formData.department ? this.formData.department:[])
          await UserService.save(this.formData);
          this.$store.dispatch("setSnackbar", { text: "Updated Successfully" });
          this.dialog = false;
          this.close();
          this.$emit("reload", true);
          this.formData = {};
           }
        } else {
          this.dateError = this.formData.date_of_join ? false : true;
           if(!this.dateError || !this.formData.department ||this.formData.department.length == 0){
            this.formData.work_timings = JSON.stringify(
            this.selectedWorkTimingId
          );
          console.log(this.formData);
          try {
            this.loading = true;
            this.formData.department = JSON.stringify(this.formData.department ? this.formData.department:[])
            await UserService.addUser(this.formData);
            this.$store.dispatch("setSnackbar", { text: "Saved Successfully" });
            this.dialog = false;
            this.close();
            this.$emit("reload", true);
            this.formData = {};
          } catch (e) {
            this.$store.dispatch("setSnackbar", {
              color: "error",
              text: "Something Went Wrong",
            });
          }
           }
        }
      }
    },
  },
};
</script>

<style>
.mobile {
  line-height: 12px;
  color: red;
  font-family: sans-serif;
  font-size: 12px;
}
.v-messages__message {
  line-height: 12px;
  word-break: break-word;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  hyphens: auto;
  transition-duration: 150ms;
  color: red;
}
</style>
